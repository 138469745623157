<template>
  <section>
    <VToolbar
      class="pt-2 pb-2"
      color="primary"
      flat
      dense
      dark
    >
      <VToolbarTitle id="toolbarTitle">
        {{ pageTitle }}
      </VToolbarTitle>
    </VToolbar>
    <section id="main">
      <div id="info">
        <VSheet color="transparent">
          <p class="textTitle">
            Hello, {{ data.name }}
          </p>
          <p class="textSubTitleNormal">
            Here's your receipt for the month of {{ data.month }} {{ formatDate(data.date) }}
          </p>
        </VSheet>
        <VSheet
          color="transparent"
          class="mt-4 mb-5"
        >
          <div class="grid_2">
            <p class="text-uppercase textSubTitleNormal">
              Date:
            </p>
            <p class="text-uppercase textSubTitleNormal textBold">
              {{ data.date }}
            </p>

            <p class="text-uppercase textSubTitleNormal">
              Session:
            </p>
            <p class="text-uppercase textSubTitleNormal textBold">
              {{ data.session }}
            </p>

            <p class="text-uppercase textSubTitleNormal">
              Class:
            </p>
            <p class="text-uppercase textSubTitleNormal textBold">
              {{ data.class }}
            </p>

            <p class="text-uppercase textSubTitleNormal">
              ADM No:
            </p>
            <p class="text-uppercase textSubTitleNormal textBold">
              {{ data.admNo }}
            </p>
          </div>
        </VSheet>
        <div v-if="data.method === 'Cheque'">
          <p class="textTitle text-uppercase">
            Fees Information
          </p>
          <VSheet
            color="transparent"
            class="mt-3 mb-5"
          >
            <div class="grid_2">
              <p class="text-uppercase textSubTitleNormal">
                Tuition Fee:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.tuitionFee.toLocaleString('en-IN') }}
              </p>

              <p class="text-uppercase textSubTitleNormal">
                Late Fee:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.lateFee.toLocaleString('en-IN') }}
              </p>

              <p class="text-uppercase textSubTitleNormal">
                Bank Charge:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.bankFee.toLocaleString('en-IN') }}
              </p>

              <p class="text-uppercase textSubTitleNormal">
                Miscellaneous:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.miscFee.toLocaleString('en-IN') }}
              </p>

              <p class="text-uppercase textSubTitleNormal">
                Total:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.totalFee.toLocaleString('en-IN') }}
              </p>

              <div class="span_2 mt-4">
                <p class="text-uppercase textSubTitleNormal textBold span_2 textSmall">
                  {{ data.amountInWord }}
                </p>
                <p class="text-uppercase textSubTitleNormal textSmall textBold fontColorSub">
                  Amount In Word
                </p>
              </div>
              <div class="span_2 mb-2">
                <p class="text-uppercase textSubTitleNormal textBold span_2">
                  {{ data.method }}
                </p>
                <p class="text-uppercase textSubTitleNormal textSmall textBold fontColorSub">
                  Payment Method
                </p>
              </div>
            </div>
          </VSheet>
          <p class="textTitle text-uppercase">
            Cheque Information
          </p>
          <VSheet
            color="transparent"
            class="mt-3 mb-2"
          >
            <div class="grid_2">
              <p class="text-uppercase textSubTitleNormal">
                Cheque Number:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.cheque.number }}
              </p>

              <p class="text-uppercase textSubTitleNormal">
                Date Of Issue:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.cheque.date }}
              </p>

              <p class="text-uppercase textSubTitleNormal">
                Bank Name:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.cheque.bank }}
              </p>

              <p class="text-uppercase textSubTitleNormal">
                Bank Branch:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.cheque.branch }}
              </p>

              <p class="text-uppercase textSubTitleNormal">
                Depositor:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.cheque.depositor }}
              </p>
            </div>
          </VSheet>
        </div>
        <div v-else>
          <p class="textTitle text-uppercase">
            Fees Information
          </p>
          <VSheet
            color="transparent"
            class="mt-3 mb-2"
          >
            <div class="grid_2">
              <p class="text-uppercase textSubTitleNormal">
                Tuition Fee:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.tuitionFee.toLocaleString('en-IN') }}
              </p>

              <p class="text-uppercase textSubTitleNormal">
                Late Fee:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.lateFee.toLocaleString('en-IN') }}
              </p>

              <p class="text-uppercase textSubTitleNormal">
                Bank Charge:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.bankFee.toLocaleString('en-IN') }}
              </p>

              <p class="text-uppercase textSubTitleNormal">
                Miscellaneous:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.miscFee.toLocaleString('en-IN') }}
              </p>

              <p class="text-uppercase textSubTitleNormal">
                Total:
              </p>
              <p class="text-uppercase textSubTitleNormal textBold">
                {{ data.totalFee.toLocaleString('en-IN') }}
              </p>

              <div class="span_2 mt-4">
                <p class="text-uppercase textSubTitleNormal textBold span_2 textSmall">
                  {{ data.amountInWord }}
                </p>
                <p class="text-uppercase textSubTitleNormal textSmall textBold fontColorSub">
                  Amount In Word
                </p>
              </div>
              <div class="span_2 mb-1">
                <p class="text-uppercase textSubTitleNormal textBold span_2">
                  {{ data.method }}
                </p>
                <p class="text-uppercase textSubTitleNormal textSmall textBold fontColorSub">
                  Payment Method
                </p>
              </div>
            </div>
          </VSheet>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import moment from 'moment';

export default {
  data: () => ({
    pageTitle: 'receipt',
    data: '',
    dataArr: '',
  }),
  beforeMount() {
    this.dataArr = this.$store.state.receipt.data
      .filter(data => data.id === this.$route.params.id);
    if (this.dataArr.length > 0) {
      this.data = this.dataArr[0].data;
    } else {
      this.$router.push('/');
    }
  },
  methods: {
    formatDate(date) {
      return moment(date, 'DD-MM-YYYY').format('YYYY');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/text';
@import '../../assets/stylus/receipt';
</style>
